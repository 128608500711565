import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    form: {}
};

const formDataSlice = createSlice({
    name: 'formData',
    initialState,
    reducers: {
        updateForm: (state, action) => {
            state.form = action.payload;
        },
        resetForm: (state) => {
            state.form = {};
        }
    }
});

const { actions, reducer } = formDataSlice;

export default reducer;

export const { updateForm, resetForm } = actions; // Export resetForm action
