export const useHttp = () => {
    const request = async (
        url,
        method = 'GET',
        body = null,
        query = null,
        redirect = true,
        headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('access_token')
        }
    ) => {
        let data, response = null;

        try {
            if (query) {
                url += `?${new URLSearchParams(query)}`;
            }

            let init = {
                method,
                headers
            };

            if (body) {
                if (body instanceof FormData) {
                    // Remove Content-Type header if body is FormData
                    delete headers['Content-Type'];
                    init.body = body;
                } else {
                    init.body = JSON.stringify(body);
                }
            }

            response = await fetch(`${window.env.API_URL}/${url}`, init);

            if (!response.ok && redirect) {
                console.error('error');
                return window.location = '/error-page';
            }

            data = await response.json();
        } catch (e) {
            // do nothing
        }

        return { json: data, response: response };
    };

    return {
        request,
    };
};